<template>
    <div class="page-rooter-fff">
      <nav-bar v-if="appTypeStr==='ios' || appTypeStr==='and'" header-title="" :show-back="true" :header-back="back" :headerBack ="headerBack"/>
      <app-page>
          <div class="main all-center">
            <img class="empty-img" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/selfGroupClass/emptyPage.png">
            <div v-if="caseStr === '1'" class="empty-text" >
                抱歉，小程序暂时无法展示<br>
                请前往<span style="color:#FFDE00">超鹿app</span>打开
            </div>
            <div v-else-if="caseStr==='2'" class="empty-text" >
                抱歉，活动不存在或已结束，小鹿带你回去吧～ <br>
                正在回到首页…<span style="color:#FFDE00">{{time}}s</span>
            </div>
              <div v-else class="empty-text" >
                  抱歉，活动不存在或已结束。
              </div>
          </div>
      </app-page>
    </div>
  </template>

  <script>
      import wx from "weixin-js-sdk"
  import appMixin from "@/mixin/appMixin";
  import navBar from "@/components/nav-bar/nav-bar";
  import appPage from "@/common/components/appPage";
  import {
    appGetCityId,
    appGetLocal,
    appGetUser,
    appGetDevice,
    appGetLocalVenue,
    appGetToken,
    appBack,
    appType
  } from "../lib/appMethod";

  export default {
    data() {
      return {
        appTypeStr:'',
          time:3,
          interver:'',
          isUndo:'',
          caseStr:'',
      };
    },
    mixins: [appMixin],
    components: {
      navBar,
      appPage
    },
    created() {
        this.appTypeStr=appType()
        this.isUndo =  this.id = this.$route.query.isUndo
        if (this.isUndo ==="1"){
            this.caseStr = '1' // 小程序没做的原生页
        } else if(this.appTypeStr==='ios' || this.appTypeStr==='and' || this.appTypeStr==='mini'){
            this.caseStr='2' // 小程序 ios android 内的h5页活动结束
        }
      this.load();
    },
    mounted() {},
    methods: {
      // 页面初次加载
      async load() {
          if (this.appTypeStr === 'ios' || this.appTypeStr === 'and'){
              try{
                  this.appCommParams = await this.getAppParams([
                      appGetCityId(),
                      appGetLocal(),
                      appGetUser(),
                      appGetDevice(),
                      appGetLocalVenue(),
                      appGetToken(),
                  ]);
                  console.log(this.appCommParams);
              }catch(err){
                  console.log('不是APP环境环境错误')
              }
          }
          if(this.caseStr==='2'){
              this.interval = setInterval(()=>{
                  this.time--
                  if (this.time===0){
                      clearInterval(this.interval)
                      this.headerBack()
                  }
              },1000)
          }

        // this.getTestData();
      },
      // 测试数据获取
      // async getTestData() {
      //   let that = this;
      //   let url = `${this.baseURL}/anniversary2020/getBillboard`;
      //   let res = await that.$axios.post(url);
      //   if (res.code === "1") {
      //     console.log(res.data);
      //   }
      // },
      headerBack(){
          if (this.appTypeStr === 'ios' || this.appTypeStr === 'and'){
              appBack()
          } else if (this.appTypeStr === 'mini'){
            console.log('mini back')
            wx.miniProgram.reLaunch({
                url:'/pages/home/index'
            })
        }
      }
    },
  };
  </script>

  <style lang="less" scoped>
  .page-rooter-fff {
      .c-img {
        width: 100%;
        position: relative;
        img {
          width: 100%;
          display: block;
        }
        .btn {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;

          .btn-img {
            display: block;
            margin: 0 auto;
            width: 43.7333333vw !important;
          }
        }
      }
  }
  .all-center{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .main{
    width: 100%;
    overflow: hidden;
  }
  .empty-img{
    display: block;
    margin: 0 auto;
    width: 6.25rem;
    height: 6.25rem;
  }
    .empty-text{
      margin-top: 1.5rem;
      height: 2.75rem;
      font-size: 0.88rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6C727A;
      line-height: 1.38rem;
      text-align: center;
    }
  </style>
